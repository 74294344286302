
export default {
    popover:null,
    mounted(el,binding) {
        binding.popover = bootstrap.Popover.getOrCreateInstance(el) 
 
    },

    unmounted(_, binding) {
        if(binding.popover) binding.popover.dispose();
    }
}



